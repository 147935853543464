import NavigationMenu from '../components/NavigationMenu';
import Footer from '../components/Footer';

const About = () => {
  return (
    <>
      <NavigationMenu searchType={'keyword'} />
      <div className="about">
        <p>Bienvenue sur CinéStreaming !</p>
        <p>
          {' '}
          Je suis Mahdi, developpeur junior. et créateur passionné de cette plateforme de streaming. Mon objectif est de vous offrir une expérience cinématographique exceptionnelle, directement depuis le confort de votre foyer.
          <br />
          <br /> Chez CinéStreaming, nous rassemblons une vaste bibliothèque de films et de séries, allant des derniers blockbusters holly-woodiens aux classiques intemporels, pour satisfaire tous les goûts. Nous sommes constamment à l'affût des nouvelles sor-ties et des tendances émergentes pour vous proposer un flux régulier de contenus frais et excitants. <br />
          <br /> Nous croyons fermement à la qualité de l'expérience de vision-nage. C'est pourquoi nous avons mis en place une infrastruc-ture robuste pour vous offrir une diffusion fluide et sans inter-ruption, sur tous vos appareils. <br />
          <br /> Rejoignez-nous dès maintenant sur CinéStreaming et plongez dans un monde de divertissement cinématographique sans limites.
        </p>
        <p> Votre createur web pationne, Mahdi !</p>
      </div>

      <Footer />
    </>
  );
};

export default About;
