import { useState } from 'react';
import { useMoviesContext } from '../contexts/MoviesContext';
import { useNavigate } from 'react-router-dom';

const SearchInput = ({
  searchType,
  selectedCategory,
  handleCategoryChange,
  categories,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const { dispatch } = useMoviesContext();
  const navigate = useNavigate();

  const handleKeywordSearch = () => {
    console.log('searchInput', searchInput);
    dispatch({ type: 'SET_SEARCH_KEYWORD', payload: searchInput });
    dispatch({ type: 'SET_CATEGORY', payload: '' });
    navigate('/movies/search');
  };

  return (
    <>
      {searchType === 'keyword' ? (
        <input
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
          type="text"
          placeholder="Entrer un mot clé"
        />
      ) : (
        <select
          value={selectedCategory !== '' ? selectedCategory : categories[0]?.id}
          onChange={handleCategoryChange}
        >
          <option value="">Select a category</option>
          {categories &&
            categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
        </select>
      )}
      {searchType === 'keyword' && (
        <button onClick={handleKeywordSearch}>Recherche</button>
      )}
    </>
  );
};

export default SearchInput;
