import { useParams } from 'react-router-dom';
import useTmdbActorDetails from '../hooks/useTmdbActorDetails';
import MovieCard from '../components/MovieCard';
import NavigationMenu from '../components/NavigationMenu';
import Footer from '../components/Footer';

const ActorDetails = () => {
  const { actorId } = useParams();
  const { actorInfo, loading } = useTmdbActorDetails(actorId);

  if (loading) {
    return <p>Loading Actor information</p>;
  }

  if (!actorInfo) {
    return <p>Error fetching actor information</p>;
  }

  const convertToFiveStars = (voteAverage) => {
    return (voteAverage / 2).toFixed(1);
  };

  const profilPic = `https://image.tmdb.org/t/p/w300${actorInfo.profile_path}`;
  const movies = actorInfo.credits.cast;

  return (
    <>
      <NavigationMenu searchType={'keyword'} />

      <div className="actor-details">
        <img className="actor-img" src={profilPic} alt={actorInfo.name} />
        <h1 className="actor-name">{actorInfo.name}</h1>
        <p className="actor-bio">{actorInfo.biography}</p>
        <p className="actor-nmovies">
          <strong>Film réalisés: </strong>
          {movies.length}
        </p>
      </div>
      <>
        <h4 style={{ textAlign: 'left', padding: '0 5rem' }}>Films</h4>
      </>
      <div className="similar-movies-grid">
        {movies.slice(0, 4).map((movie) => (
          <MovieCard
            key={movie.id}
            id={movie.id}
            imageUrl={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
            title={movie.title}
            rating={convertToFiveStars(movie.vote_average)}
            description={movie.overview}
          />
        ))}
      </div>
      <Footer />
    </>
  );
};

export default ActorDetails;
