import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TrendingMovies from './pages/TrendingMovies';
import MoviesByCategoryList from './pages/MoviesByCategoryList';
import MovieSearch from './pages/MovieSearch';
import MovieDetails from './pages/MovieDetails';

import './style.css';
import About from './pages/About';
import ActorDetails from './pages/ActorDetails';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<TrendingMovies />} />
          <Route exact path="/about" element={<About />} />

          <Route
            exact
            path="/movies/category"
            element={<MoviesByCategoryList />}
          />
          <Route exact path="/movies/search" element={<MovieSearch />} />
          <Route exact path="/movie/:movieId" element={<MovieDetails />} />
          <Route exact path="/actor/:actorId" element={<ActorDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
