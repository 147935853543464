import React from 'react';
import MovieList from '../components/MoviesList';
import NavigationMenu from '../components/NavigationMenu';
import useTmdbTrendMovies from '../hooks/useTmdbTrendMovies';
import { useMoviesContext } from '../contexts/MoviesContext';
import Footer from '../components/Footer';

const TrendingMovies = () => {
  const { state } = useMoviesContext();
  const { currentPage } = state;
  return (
    <div>
      <NavigationMenu searchType={'keyword'} />
      <h2
        style={{ marginLeft: '2.5rem', color: '#4B4B4D', fontWeight: 'bold' }}
      >
        Populaires
      </h2>
      <MovieList moviesData={useTmdbTrendMovies(currentPage)} />
      <Footer />
    </div>
  );
};

export default TrendingMovies;
