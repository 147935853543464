import logo from '../images/logo-red.png';
import instagram from '../images/icons/Instagram.svg';
import pinterest from '../images/icons/Pinterest.svg';
import twitter from '../images/icons/Twitter.svg';

const Footer = () => {
  return (
    <div className="footer">
      <h5>Suivez-nous</h5>
      <div className="social">
        <img src={twitter} alt="twitter" />
        <img src={instagram} alt="instagram" />
        <img src={pinterest} alt="pinterest" />
      </div>
      <div className="logo">
        <img src={logo} alt="logo" />
        <h4>Cinestream</h4>
      </div>
      <p className="description">
        CineStream est un site web de streaming de films conçu pour offrir aux
        utilisateurs une expérience cinématographique immersive et
        divertissante. Inspiré par le succès de plateformes telles que Netflix,
        notre objectif est de créer une destination en ligne où les utilisateurs
        peuvent découvrir, regarder et apprécier une vaste sélection de films
        provenant du monde entier.
      </p>
      <div className="bar">
        <p>©Cinestream</p>
      </div>
    </div>
  );
};

export default Footer;
