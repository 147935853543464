import React, { useMemo } from 'react';
import { useMoviesContext } from '../contexts/MoviesContext'; // Import the context hook
import Pagination from '../components/Pagination';
import MovieCard from './MovieCard';

const MovieList = ({ moviesData }) => {
  let { state } = useMoviesContext();
  const { dispatch } = useMoviesContext();

  const { currentPage, totalPages: pages } = state;

  const { movies, loading } = moviesData;

  // const itemsPerPage = 20;

  const totalPages = Math.min(Math.ceil(pages), 7);

  const handlePageChange = (pageNumber) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: pageNumber });
    window.scroll({ top: 0 });
  };

  const convertToFiveStars = (voteAverage) => {
    return (voteAverage / 2).toFixed(1);
  };

  const currentMovies = useMemo(() => movies, [movies]);

  if (loading) {
    return <p>Loading movie information</p>;
  }

  return (
    <div>
      {!loading && currentMovies.length > 0 ? (
        <>
          <div className="movie-container">
            {currentMovies.map((movie) => (
              <MovieCard
                key={movie.id}
                id={movie.id}
                imageUrl={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                title={movie.title}
                rating={convertToFiveStars(movie.vote_average)}
                description={movie.overview}
              />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        <div style={{ margin: '5rem 0' }}>
          <div className="loop"></div>
          <h3 style={{ textAlign: 'center' }}>
            Le résultat de la recherche est introuvable. <br /> Nous vous
            invitons à essayer un autre film.
          </h3>
        </div>
      )}
    </div>
  );
};

export default MovieList;
