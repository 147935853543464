import { useEffect, useState } from 'react';
import axios from 'axios';
import { useMoviesContext } from '../contexts/MoviesContext';

const API_KEY = 'b3e4a8a4983d04ddec2833d5e8588847';
const TMDB_API_URL = 'https://api.themoviedb.org/3/movie/now_playing';

const useTmdbTrendMovies = (currentPage) => {
  const { state, dispatch } = useMoviesContext();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await axios.get(
          `${TMDB_API_URL}?api_key=${API_KEY}&page=${currentPage}`
        );
        dispatch({ type: 'SET_MOVIES', payload: response.data.results });
        dispatch({
          type: 'SET_TOTAL_PAGES',
          payload: response.data.total_pages,
        });
      } catch (error) {
        setError('Error fetching movies');
      } finally {
        setLoading(false);
      }
    };

    fetchMovies();
  }, [dispatch, currentPage]);

  return {
    movies: state.movies,
    total_pages: state.total_pages,
    loading,
    error,
  };
};

export default useTmdbTrendMovies;
