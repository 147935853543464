import axios from 'axios';
import React, { useEffect } from 'react';

const API_KEY = 'b3e4a8a4983d04ddec2833d5e8588847';
const TMDB_API_URL = 'https://api.themoviedb.org/3/movie/';

const useTmdbMovieDetails = (movieId) => {
  const [movieInfo, setMovieInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchMovieInfo = async () => {
      try {
        const response = await axios.get(
          `${TMDB_API_URL}${movieId}?api_key=${API_KEY}&language=fr-FR&append_to_response=credits,similar,watch/providers`
        );
        setMovieInfo(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching movie information:', error);
        setLoading(false);
      }
    };

    fetchMovieInfo();
  }, [movieId]);

  return { movieInfo, loading };
};

export default useTmdbMovieDetails;
