import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="pagination-container">
      {pageNumbers.map((pageNumber) => (
        <span
          key={pageNumber}
          onClick={() => onPageChange(pageNumber)}
          style={{
            cursor: 'pointer',
            margin: '5px',
            fontWeight: pageNumber === currentPage ? 'bold' : 'normal',
          }}
          className={`${pageNumber === currentPage && 'active'}`}
        >
          {pageNumber}
        </span>
      ))}
    </div>
  );
};

export default Pagination;
