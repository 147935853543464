import { useState, useEffect } from 'react';
import axios from 'axios';
import { useMoviesContext } from '../contexts/MoviesContext';

const API_KEY = 'b3e4a8a4983d04ddec2833d5e8588847';
const TMDB_API_URL = 'https://api.themoviedb.org/3';

const useTmdbSearchMovies = (searchKeyword, pageNumber) => {
  const { state, dispatch } = useMoviesContext(); // Get state and dispatch from context

  const { movies, currentPage } = state;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axios.get(
          `${TMDB_API_URL}/search/movie?api_key=${API_KEY}&query=${searchKeyword}&page=${pageNumber}`
        );
        dispatch({ type: 'SET_MOVIES', payload: response.data.results }); // Update movies in the context
        dispatch({
          type: 'SET_TOTAL_RESULTS',
          payload: response.data.total_results,
        });

        setLoading(false);
      } catch (error) {
        setError('Error fetching movies');
        setLoading(false);
      }
    };

    fetchMovies();
  }, [searchKeyword, dispatch, currentPage, pageNumber]);

  return { movies, loading, error };
};

export default useTmdbSearchMovies;
