import { useEffect, useState } from 'react';
import axios from 'axios';

const API_KEY = 'b3e4a8a4983d04ddec2833d5e8588847';
const CATEGORIES_URL = "https://api.themoviedb.org/3/genre/movie/list"


const useFetchCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${CATEGORIES_URL}?api_key=${API_KEY}`); 
        setCategories(response.data.genres);
        setLoading(false);
      } catch (error) {
        setError('Error fetching categories');
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return { categories, loading, error };
};

export default useFetchCategories;