import React from 'react';
import MovieList from '../components/MoviesList';
import NavigationMenu from '../components/NavigationMenu';
import { useMoviesContext } from '../contexts/MoviesContext';
import useTmdbSearchMovies from '../hooks/useTmdbSearchMovies';
import Footer from '../components/Footer';

const MovieSearch = () => {
  const { state } = useMoviesContext();
  const { searchKeyword, currentPage } = state;
  return (
    <div>
      <NavigationMenu searchType={'keyword'} />
      <MovieList moviesData={useTmdbSearchMovies(searchKeyword, currentPage)} />
      <Footer />
    </div>
  );
};

export default MovieSearch;
