import React, { useState } from 'react';
import useFetchCategories from '../hooks/useFetchCategories';
import SearchInput from './SearchInput';
import { useMoviesContext } from '../contexts/MoviesContext';

import { useNavigate } from 'react-router-dom';

import logo from '../images/logo-white.svg';

const NavigationMenu = ({ searchType }) => {
  const navigate = useNavigate();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  let { state } = useMoviesContext();
  const { dispatch } = useMoviesContext();

  const { selectedCategory } = state;

  const { categories } = useFetchCategories();

  const searchCategories = categories || [];

  const handleCategoryChange = (event) => {
    dispatch({ type: 'SET_CATEGORY', payload: event.target.value });
  };

  const handleMobileMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const navigateToHome = () => {
    navigate('/');
  };

  const navigateToAbout = () => {
    navigate('/about');
  };

  const navigateToCategories = () => {
    navigate('/movies/category');
  };

  return (
    <>
      <nav
        className={'navigation-menu'}
        style={
          showMobileMenu
            ? {
                position: 'fixed',
                width: '100%',
                top: '0',
                backgroundColor: '#bf0000',
              }
            : {}
        }
      >
        <div className="logo">
          <img src={logo} alt="logo" width={100} />
        </div>
        <ul className="menu-links">
          <li>
            <button onClick={navigateToHome}>Accueil</button>
          </li>
          <li>
            <button onClick={navigateToAbout}>À propos</button>
          </li>
          <li>
            <button onClick={navigateToCategories}>Catégories</button>
          </li>
        </ul>
        <div className="search">
          <SearchInput
            categories={searchCategories}
            handleCategoryChange={handleCategoryChange}
            searchType={searchType}
            selectedCategory={selectedCategory}
          />
        </div>
        <div
          className={`hamburger-menu ${showMobileMenu ? 'active' : ''}`}
          onClick={handleMobileMenuToggle}
        >
          <div className="hamburger">&nbsp;</div>
        </div>
        {showMobileMenu && (
          <div className="mobile-menu-overlay">
            <ul className="mobile-menu-links">
              <li>
                <button onClick={navigateToHome}>Accueil</button>
              </li>
              <li>
                <button onClick={navigateToAbout}>À propos</button>
              </li>
              <li>
                <button onClick={navigateToCategories}>Catégories</button>
              </li>
            </ul>
          </div>
        )}
      </nav>

      <div className="search mobile-search">
        <SearchInput
          categories={searchCategories}
          handleCategoryChange={handleCategoryChange}
          searchType={searchType}
          selectedCategory={selectedCategory}
        />
      </div>
    </>
  );
};

export default NavigationMenu;
