import { Link, useNavigate, useParams } from 'react-router-dom';
import useTmdbMovieDetails from '../hooks/useTmdbMovieDetails';
import MovieCard from '../components/MovieCard';
import NavigationMenu from '../components/NavigationMenu';
import Footer from '../components/Footer';

const MovieDetails = () => {
  const navigate = useNavigate();
  const { movieId } = useParams();
  const { movieInfo, loading } = useTmdbMovieDetails(movieId);

  const convertToFiveStars = (voteAverage) => {
    return (voteAverage / 2).toFixed(1);
  };

  if (loading) {
    return <p>Loading movie information</p>;
  }

  if (!movieInfo) {
    return <p>Error fetching actor information</p>;
  }

  const handleActorDetails = (actorId) => {
    navigate(`/actor/${actorId}`);
  };

  const mainActors = movieInfo.credits.cast.slice(0, 3); // Get the first 3 actors
  const director =
    movieInfo.credits.crew.find((crew) => crew.job === 'Director')?.name ||
    'N/A';
  const similarMovies = movieInfo.similar.results.slice(0, 4);
  const justWatchProviders = movieInfo['watch/providers']?.results?.CA?.link;

  return (
    <>
      <NavigationMenu searchType={'keyword'} />

      <div className="movie-details">
        <div className="poster">
          <img
            src={`https://image.tmdb.org/t/p/w300${movieInfo.poster_path}`}
            alt={movieInfo.title}
          />
        </div>
        <div className="info">
          <span className="title">
            <h2>{movieInfo.title}</h2>
            <div className="rating">
              {Array.from(
                { length: convertToFiveStars(movieInfo.vote_average) },
                (_, index) => (
                  <span key={index} className="star">
                    ★
                  </span>
                )
              )}
            </div>
          </span>

          <p className="description">{movieInfo.overview}</p>
          <p>
            <strong>Production Country:</strong>{' '}
            {movieInfo.production_countries[0]?.name || 'N/A'}
          </p>
          <p>
            <strong>Director:</strong> {director}
          </p>
          <p>
            <strong>Year of Production:</strong>{' '}
            {movieInfo.release_date
              ? movieInfo.release_date.substring(0, 4)
              : 'N/A'}
          </p>
          <h3>Acteurs principaux:</h3>
          {mainActors.map((actor) => (
            <span className="actor" key={actor.id}>
              <strong
                style={{ cursor: 'pointer' }}
                onClick={() => handleActorDetails(actor.id)}
              >
                {actor.name}
              </strong>
              <br />
              {actor.character}
              <br />
            </span>
          ))}
          {justWatchProviders && (
            <button className="watch-button">
              <Link
                style={{
                  textDecoration: 'none',
                  color: 'white',
                }}
                to={justWatchProviders}
              >
                Regarder
              </Link>
            </button>
          )}
        </div>
      </div>
      <>
        <h4 style={{ textAlign: 'center' }}>
          Si vous aimez ce film, <br /> vous pourriez aimer ...
        </h4>
      </>
      <div className="similar-movies-grid">
        {similarMovies.map((movie) => (
          <MovieCard
            key={movie.id}
            id={movie.id}
            imageUrl={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
            title={movie.title}
            rating={convertToFiveStars(movie.vote_average)}
            description={movie.overview}
          />
        ))}
      </div>
      <Footer />
    </>
  );
};

export default MovieDetails;
