import React from 'react';
import { useNavigate } from 'react-router-dom';

const MovieCard = ({ id, title, rating, description, imageUrl }) => {
  const navigate = useNavigate();
  const truncateDescription = (text, limit) => {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  };

  const handleDetailsRedirect = () => {
    navigate(`/movie/${id}`);
  };

  return (
    <div className="movie-card" key={id}>
      <div className="header">
        <div
          className="header"
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
      </div>
      <div className="info">
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h5 className="title">{title}</h5>
            <div className="rating">
              {Array.from({ length: rating }).map((_, index) => (
                <span key={index} className="star">
                  &#9733;
                </span>
              ))}
            </div>
          </div>
          <button className="watch-button" onClick={handleDetailsRedirect}>
            Regarder
          </button>
        </div>

        <div className="description">
          <p>{truncateDescription(description, 32)}</p>
        </div>
      </div>
    </div>
  );
};

export default MovieCard;
