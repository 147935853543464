import React from 'react';
import MovieList from '../components/MoviesList';
import NavigationMenu from '../components/NavigationMenu';
import useTmdbCategoryMovies from '../hooks/useTmdbCategoryMovies';
import { useMoviesContext } from '../contexts/MoviesContext';
import Footer from '../components/Footer';

const MoviesByCategory = () => {
  const { state } = useMoviesContext();
  const { currentPage, selectedCategory } = state;
  return (
    <div>
      <NavigationMenu searchType={'categories'} />
      <h2
        style={{ marginLeft: '2.5rem', color: '#4B4B4D', fontWeight: 'bold' }}
      >
        Film
      </h2>
      <MovieList
        moviesData={useTmdbCategoryMovies(currentPage, selectedCategory)}
      />
      <Footer />
    </div>
  );
};

export default MoviesByCategory;
